<template>
  <div v-if="dataLoading" class="spinner-border m-auto" role="status">
    <span class="sr-only">Loading... </span>
  </div>
  <div v-else-if="error.length !== 0" class="m-auto">
    {{ error }}
  </div>
  <div v-else-if="error.length === 0 && can_list" style="width: 100%" class="card-body">
    <div class="filter">
      <div class="row">
        <div class="col-md-4 dx-field">
          <div class="dx-field-label">From</div>
          <div class="dx-field-value">
            <DxDateBox
                type="date"
                :value="filterModel.startDate"
                :show-clear-button="true"
                @value-changed="filterDateChange"
            />
          </div>
        </div>
        <div class="col-md-4  dx-field">
          <div class="dx-field-label">To</div>
          <div class="dx-field-value">
            <DxDateBox
                type="date"
                :value="filterModel.endDate"
                :show-clear-button="true"
                @value-changed="filterDateChangeEnd"
            />
          </div>
        </div>
        <div class="col-md-4  dx-field">
          <div class="dx-field-label">User</div>
          <div class="dx-field-value">
            <DxSelectBox
                :search-enabled="true"
                :items="dataUser"
                :show-clear-button="true"
                display-expr="fullname"
                value-expr="id"
                @value-changed="userDataChange"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4  dx-field">
          <div class="dx-field-label">Responsible</div>
          <div class="dx-field-value">
            <DxSelectBox
                :search-enabled="true"
                :items="positions"
                :show-clear-button="true"
                display-expr="name"
                value-expr="id"
                @value-changed="responsibleDataChange"
            />
          </div>
        </div>
        <div class="col-md-4 dx-field">
          <div class="dx-field-label">WBS</div>
          <div class="dx-field-value">
            <DxSelectBox
                :search-enabled="true"
                :items="dataWbs"
                :show-clear-button="true"
                display-expr="mobile_name"
                value-expr="id"
                @value-changed="wbsDataChange"
            />
          </div>
        </div>
        <div class="col-md-4 dx-field">
          <div class="dx-field-label">Activity</div>
          <div class="dx-field-value">
            <DxSelectBox
                :search-enabled="true"
                :items="dataActivities"
                :show-clear-button="true"
                display-expr="name"
                value-expr="id"
                @value-changed="activityDataChange"
            />
          </div>
        </div>
      </div>


    </div>
    <div class="row">
      <div class="col">
        <DxButton
            :width="120"
            :text="$t('filter')"
            type="success"
            styling-mode="contained"
            @click="filterClick()"
        />
      </div>
    </div>

    <div class="images">
      <div v-for="works in data" :key="works.id">
        <div
            v-if="works.work_images.length !== 0  "
            class="item-content"
            @click="showHouse(works)"
        >
          <img
              v-if="works.work_images[0].image != null"
              :src="
              'data:image/png;base64,' +
              blobToBase64(works.work_images[0].image.data)
            "
              fluid
          />
          <div class="item-options">
            <div>
              <div class="address">
                {{ works.userUser }}
              </div>
              <div class="price large-text">
                {{ works.activity.languages[0].activity_translation.name }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <DxPopup
          :visible="popupVisible"
          :width="auto"
          :height="auto"
          :show-title="true"
          :resize-enabled="true"
          :dragEnabled="true"
          :title="
          getFirm(currentHouse.firm_id) +
          ' - ' +
          formatDate(currentHouse.work_date)
        "
          :drag-enabled="false"
          :close-on-outside-click="false"
          :show-close-button="false"
      >
        <DxToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            :options="closePopupButton"
        />
        <template #content>
          <div class="popup-property-details">
            <div class="large-text">
              {{
                currentHouse.quantity + ' ' + (currentHouse.activity.unit ? currentHouse.activity.unit.symbol : '')
              }}
            </div>
            <div class="opacity">
              {{
                currentHouse.activity.languages[0].activity_translation.name
              }},
              {{ currentHouse.activity.wb.languages[0].wbs_translation.name }}
            </div>
            <div class="opacity">
              {{ currentHouse.not_exists_activity }}
              {{ currentHouse.not_exists_activity_unit_id }}
            </div>

            <div class=" imagesInPop">
              <div class="row-img" v-for="(images,inedx) in currentHouse.work_images" :key="images">
                <img class="col-img" :id="'img'+images.id"
                     v-if="images.image != null && inedx <=2"
                     :src="
                    'data:image/png;base64,' + blobToBase64(images.image.data)
                  "
                />
                <div class="col-down">
                  <DxButton
                      class="down-icon"
                      icon="download"
                      :text="$t('download')"
                      @click="downloadItem(images.id, getFirm(currentHouse.firm_id) +
          ' - ' +
          formatDate(currentHouse.work_date))"/>
                  <div style="height: 0px;width: 0px;overflow:hidden;">
                    <a :id="'imgdwn' + images.id" :visible="false">{{ $t('download') }}</a>

                  </div>
                </div>

              </div>
            </div>

            <div>{{ currentHouse.notes }}</div>
          </div>
        </template>
      </DxPopup>
    </div>
  </div>
  <h1 v-else>{{ $t("noPermission") }}</h1>

</template>
<script>
import axios from '@axios';
import {DxPopup, DxToolbarItem} from 'devextreme-vue/popup';
import {DxButton} from 'devextreme-vue/button';
import {BDropdown, BDropdownItem} from 'bootstrap-vue';

import notify from 'devextreme/ui/notify';

import {housesSource} from './data.js';

let activityListF = [];
import {DxDateBox} from 'devextreme-vue/date-box';
import {DxSelectBox} from 'devextreme-vue/select-box';
import moment from 'moment';
import Axios from "@axios";

export default {
  components: {
    DxPopup,
    DxToolbarItem,
    DxDateBox,
    DxSelectBox,
    DxButton
  },
  data: function () {
    return {
      houses: housesSource,
      currentHouse: housesSource[0],
      popupVisible: false,
      position: {
        offset: '0, 2',
        at: 'bottom',
        my: 'top',
        collision: 'fit flip'
      },
      closePopupButton: {
        text: 'Close',
        onClick: () => {
          this.popupVisible = false;
        }
      },

      selectedLanguage: {},
      can_edit: false,
      can_list: false,
      can_create: false,
      can_delete: false,
      data: [],
      dataActivities: [],
      dataWbs: [],
      dataMain: [],
      dataLoading: true,
      dataUnit: [],
      dataPopUpWbsName: [],
      dataPopUpActivityName: [],
      dataPopUpUnit: [],
      dataPopUpCompany: [],
      error: '',
      filteredDate: null,
      filteredDateEnd: null,
      filteredUser: null,
      filteredResponsible: null,
      filteredActivity: null,
      filteredWbs: null,
      dataUser: [],
      today: new Date(),
      filterModel: {
        startDate: (new Date()).toISOString().substring(0, 10),
        endDate: (new Date()).toISOString().substring(0, 10),
        user: null,
        responsible: null,
        wbs: null,
        activity: null
      },
      positions: []
    };
  },
  computed: {},
  async mounted() {
    await this.getPageAuths();
    await this.getLanguage();
    await this.getDatas();
    this.refreshData();

  },
  methods: {

    downloadItem(id, name) {
      var image = document.getElementById('img' + id);       // Image you want to save
      var saveImg = document.getElementById('imgdwn' + id);       // New link we use to save it with
      saveImg.href = image.src                         // Assign image src to our link target
      saveImg.download = name + ".jpg";
      saveImg.click()// set filename for download
      // console.log(id)
      // axios.get('/work-image-download/'+ id, )
      //     .then(response => {
      //         console.log(response.data)
      //         const blob = new Blob([response.data.image.data], { type: response.data.image.data.type });
      //         const link = document.createElement('a');
      //         link.href = URL.createObjectURL(blob);
      //         link.download = name;
      //         link.click();
      //         URL.revokeObjectURL(link.href);
      //     }).catch(console.error)
      // Axios.get(url, { responseType: 'blob' })
      //     .then(response => {
      //         const blob = new Blob([response.data], { type: 'application/pdf' })
      //         const link = document.createElement('a')
      //         link.href = URL.createObjectURL(blob)
      //         link.download = 'label'
      //         link.click()
      //         URL.revokeObjectURL(link.href)
      //     }).catch(console.error)
    },
    filterClick() {
      console.log(this.filterModel)
      this.refreshData()
    },
    async getPageAuths() {
      const pageAuths = await axios.post("/get-page-auth", {page: "Photos"});
      this.can_edit = pageAuths.data.can_edit;
      this.can_list = pageAuths.data.can_list;
      this.can_delete = pageAuths.data.can_delete;
      this.can_create = pageAuths.data.can_create;
    },
    filterDateChange(e) {
      let filterDate =
          e.value !== null ? moment(String(e.value)).format('YYYY-MM-DD') : null;
      this.filteredDate = filterDate;
      this.filterModel.startDate = filterDate

      this.filterValues();
    },
    filterDateChangeEnd(e) {
      let filterDate =
          e.value !== null ? moment(String(e.value)).format('YYYY-MM-DD') : null;
      this.filteredDateEnd = filterDate;
      this.filterModel.endDate = filterDate
      this.filterValues();
    },
    userDataChange(e) {
      this.filteredUser = e.value;
      this.filterValues();
      this.filterModel.user = e.value;
    },
    responsibleDataChange(e) {
      this.filteredResponsible = e.value;
      this.filterValues();
      this.filterModel.chief_id = e.value;
    },
    activityDataChange(e) {
      this.filteredActivity = e.value;
      this.filterValues();
      this.filterModel.activity = e.value;
    },
    wbsDataChange: function (e) {
      this.getActivityByWbs(e).then((_) => {
        this.filteredWbs = e.value;
        this.filterValues();
        this.filterModel.wbs = e.value;
      })

    },
    async getActivityByWbs(e) {
      if (e.value == null) {
        this.dataActivities = []
        return
      }
      const response = await axios.get('/list-activity-wbs?wbs_id=' + e.value);

      try {
        this.dataActivities = response?.data || []
        this.dataActivities.forEach(d => {
          if (d.languages.length > 0) {
            d.name = d.languages[0].activity_translation.name
          } else {
            d.name = ""
          }
        })
      } catch (error) {
        this.dataActivities = []
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    filterValues() {
      this.data = this.dataMain.filter(
          (p) =>
              ((p.work_date >= this.filteredDate && p.work_date <= this.filteredDateEnd) || this.filteredDate === null || this.filteredDateEnd === null) &&
              (p.user.id === this.filteredUser || this.filteredUser === null) &&
              (p.chief_id === this.filteredResponsible ||
                  this.filteredResponsible === null) &&
              (p.activity_id === this.filteredActivity ||
                  this.filteredActivity === null) &&
              (p.activity.wbs_id === this.filteredWbs || this.filteredWbs === null)
      );
      console.log(this.data)

    },
    async getDatas() {
      await this.getActivityList();
      await this.getPositions();
      await this.getUnit();
      await this.getWbsName();
      await this.getUnitName();
      await this.getUsers();
      await this.getActivities();

      await this.getCompanyName();
    },
    async getPositions() {
      this.error = '';
      const response = await axios.get('/list-positionWithHi');

      try {
        this.positions = response?.data?.result || [];
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    async getLanguage() {
      this.error = '';
      const response = await axios.get('/list-language');

      try {
        this.languages = response?.data?.result || [];
        const selected = this.languages.find(
            (x) => x?.code === localStorage.dbLanguage
        );
        this.selectedLanguage = selected;
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    getFilteredActivity: (options) => ({
      store: activityListF,
      filter: options.data ? ['wbs_id', '=', options.data.wbs_id] : null
    }),
    async getActivityList() {
      this.error = '';
      const response = await axios.get('/list-activity-dash');

      try {
        this.dataPopUpActivityName = response?.data?.result || [];
        activityListF = response?.data?.result || [];
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    async getWbsName() {
      this.error = '';
      const response = await axios.get('/list-wbs');

      try {
        this.dataPopUpWbsName = response?.data?.result || [];
        this.dataWbs = response?.data?.result || [];
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    async getCompanyName() {
      this.error = '';
      const response = await axios.get('/list-sub-firm');

      try {
        this.dataPopUpCompany = response?.data?.result || [];
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    async getUnit() {
      this.error = '';
      const response = await axios.get('/list-unit');

      try {
        this.dataUnit = response?.data?.result || [];
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    async getUnitName() {
      this.error = '';
      const response = await axios.get('/list-unit');

      try {
        this.dataPopUpUnit = response?.data?.result || [];
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    async getUsers() {
      this.error = '';
      const response = await axios.get('/list-site-user');

      try {
        this.dataUser = response?.data?.result || [];
        this.dataUser.forEach(a => {
          a.fullname = a.firstname + ' ' + a.lastname
        })
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    async getActivities() {
      this.error = '';
      const response = await axios.get('/list-activity');

      try {
        this.dataActivities = response?.data?.result || [];
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    blobToBase64(blob) {
      let result = btoa(
          blob.reduce((data, byte) => data + String.fromCharCode(byte), '')
      );
      return result
    },
    async onLanguageSelect(lang) {
      this.selectedLanguage = lang;
      localStorage.dbLanguage = lang.code;
      await this.getDatas();
      this.refreshData();
    },
    getFirm(value) {
      if (value) {
        const found = this.dataPopUpCompany.find(
            (element) => element.id == value
        );

        return found ? found.name : '';
      }
    },
    formatDate(date) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      return [year, month, day].join('-');
    },
    refreshData() {
      this.dataLoading = true;
      this.error = '';
      axios
          .post('/list-work-photos-tab-v2', this.filterModel)
          .then((response) => {
            if (response.status === 200) {
              console.log(response?.data)
              this.data = response?.data?.result || [];
              this.dataMain = response?.data?.result || [];
              this.dataLoading = false;
              const divs = document.getElementsByClassName('images');
              divs.forEach((div) => {
                if (divs.innerHTML === '' || divs.innerHTML === '<!---->') {
                  div.remove();
                }
              });
            } else {
              this.error = response?.data?.message || 'Unexpected API error';
              this.dataLoading = false;
            }
          })
          .catch((e) => {
            this.error = e?.response?.data?.message || e?.message;
            this.dataLoading = false;
          });
    },
    showHouse(house) {
      this.currentHouse = house;
      this.popupVisible = true;
    },
    changeFavoriteState() {
      let favoriteState = !this.currentHouse.Favorite;
      let message = `This item has been ${
          favoriteState ? 'added to' : 'removed from'
      } the Favorites list!`;
      this.currentHouse.Favorite = favoriteState;
      notify(
          {
            message: message,
            width: 450
          },
          favoriteState ? 'success' : 'error',
          2000
      );
    }
  }
};
</script>
<style src="./styles.css"></style>

<style>


.card-body {
  background: #ffffff;
  border-radius: 7px;
}


.item-content img {
  width: 33%;
  height: 350px;
  flex-wrap: wrap;
}

.filter {
  margin-left: 15px;
}

.dx-field-value {
  width: 90% !important;
}

.dx-rows {
  display: flex;
}

.dx-field {
  display: flex;
  flex-direction: column;
  width: 100%;
  /*margin-right: 10px;*/
}

.row-img img {
  width: 400px;
  height: 300px;

}

.row-img img:hover {
  width: 500px;
  height: 400px;

}

@media only screen and (max-width: 1000px) {
  .price {
    font-size: 10px !important;
  }

  .address {
    font-size: 10px !important;
  }

  .card-body {
    width: auto;
  }

  .item-content img {
    width: 250px;
    height: 250px;
    flex-wrap: wrap;
  }

  .down-icon {
    margin-left: 20px;
  }
}

@media only screen and (max-width: 780px ) {
  .price {
    font-size: 10px !important;
  }

  .address {
    font-size: 15px !important;
  }

  /*.card-body {*/
  /*  width: auto;*/
  /*}*/
  .item-content img {
    width: 45%;
    height: 200px;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 600px) {
  .price {
    font-size: 7px !important;
  }

  .address {
    font-size: 10px !important;
  }

  /*.card-body {*/
  /*  width: auto;*/
  /*}*/
  .item-content img {
    width: 45%;
    height: 250px;
    flex-wrap: wrap;
  }
}

</style>
