<template>
  <div>
    <div class="demo-container">
      <div id="app">
        <widget-photos />
      </div>
    </div>
  </div>
</template>

<script>
import WidgetPhotos from '@core/components/WidgetsGallery/index';

export default {
  components: {
    WidgetPhotos
  }
};
</script>

